.root {
  --img-size: var(--n-size-9);
  --eight-images: calc(var(--img-size) * 8 + var(--n-size-3) * 7 + var(--n-size-2) * 2);

  position: relative;
  max-width: min(100%, var(--eight-images));
  margin: 0 auto;
}

.items {
  scrollbar-width: none;

  overflow-x: auto;
  display: flex;
  gap: var(--n-size-3);

  padding: var(--n-size-3) var(--n-size-2);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  display: grid;
  gap: var(--n-size-1);
  place-items: center;

  & picture {
    position: relative;

    overflow: hidden;

    width: var(--img-size);
    height: var(--img-size);

    object-fit: cover;
    border: var(--n-size-half) solid var(--n-color-accent);
    border-radius: var(--radius-full);
  }

  & p {
    overflow: hidden;

    width: var(--img-size);

    font-family: Syne, sans-serif;
    font-size: var(--n-font-size-0000);
    font-weight: 700;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;

    /* Add ellipsis if too long */

    white-space: nowrap;
  }
}
